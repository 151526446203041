import * as actionTypes from '../../types';
import { updateObject } from '../../../helpers/reduxUtil'

const initState = {
    keywordsList: null,
    status: ''
}

function requestKeywordSuccess(state, action){
    return updateObject(state, {
        keywordsList: action.keywords,
        status: 'loaded'
    });
}

function requestKeyword(state, action){
    return updateObject(state, {
        status: 'loading'
    });
}


export default function keywordReducer(state = initState, action) {

    switch (action.type) {

        case actionTypes.REQUEST_KEYWORDS_SUCCESS: return requestKeywordSuccess(state, action);

        case actionTypes.REQUEST_KEYWORDS: return requestKeyword(state, action);

        default: return state;
    }

}