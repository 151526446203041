import * as actionTypes from '../../types'
import { updateObject } from '../../../helpers/reduxUtil';


const initState = {
    firstTenArticles: [],
    status: "",
    error: null,
}

function requestFirstTenArticles(state, action) {
    return updateObject(state, {
        status: "loading"
    });
}

function firstTenArticlesError(state, action) {
    return updateObject(state, {
        status: 'error',
        error: action.error
    })
}

function firstTenArticlesSuccess(state, action) {
    return updateObject(state, {
        status: '',
        firstTenArticles: action.articles,
    })
}

//First Ten Articles API
export default function firstTenArticlesReducer(state = initState, action) {

    switch (action.type) {

        case actionTypes.STARTING_TEN_ARTICLES_ERROR:
            return firstTenArticlesError(state, action);

        case actionTypes.REQUEST_STARTING_TEN_ARTICLES:
            return requestFirstTenArticles(state, action);

        case actionTypes.SUCCESS_STARTING_TEN_ARTICLES:
            return firstTenArticlesSuccess(state, action);
        
        default: return state;
    }

}