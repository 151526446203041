import * as actionTypes from '../../types';
import { updateObject } from '../../../helpers/reduxUtil'

const initState = {
    anouncements: null,
    status: "",
    error: null
}

function requestAnnouncements(state, action) {
    return updateObject(state, {
        status: 'loading'
    })
}


function announcementError(state, action) {
    return updateObject(state, {
        error: action.error,
        status: 'error'
    })
}

function announcementSuccess(state, action) {
    return updateObject(state, {
        anouncements: action.anouncements,
    })
}


//Anouncement Reducer
export default function announcementReducer(state = initState, action) {

    switch (action.type) {

        case actionTypes.REQUEST_ANNOUNCEMENTS: return requestAnnouncements(state, action);

        case actionTypes.ANNOUNCEMENTS_ERROR: return announcementError(state, action);

        case actionTypes.SUCCESS_ANNOUNCEMENTS: return announcementSuccess(state, action);

        default: return state;
    }

}