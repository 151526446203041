import { createStore, applyMiddleware, combineReducers } from 'redux'
import { HYDRATE, createWrapper } from 'next-redux-wrapper'
import thunkMiddleware from 'redux-thunk'
import announcementReducer from './Reducers/Homepage/announcementReducer';
import firstTenArticlesReducer from './Reducers/Homepage/firstTenArticleReducer';
import filteredArticleReducers from './Reducers/Homepage/filterReducer'
import getIntervalReducer from './Reducers/ArticlePage/TakeBreakReducer'

import keywordReducer from './Reducers/keywordReducer/index'

const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== 'production') {
        const { composeWithDevTools } = require('redux-devtools-extension')
        return composeWithDevTools(applyMiddleware(...middleware))
    }
    return applyMiddleware(...middleware)
}


//combining all the reducers
const combinedReducer = combineReducers({
    announcementReducer,
    firstTenArticlesReducer,
    filteredArticleReducers,
    getIntervalReducer,
    keywordReducer
})



const reducer = (state, action) => {
    if (action.type === HYDRATE) {
        const nextState = {
            ...state, // use previous state
            ...action.payload, // apply delta from hydration
        }
        return nextState
    } else {
        return combinedReducer(state, action)
    }
}

const initStore = () => {
    return createStore(reducer, bindMiddleware([thunkMiddleware]))
}

export const wrapper = createWrapper(initStore)