import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "@theme/theme";
import Head from "next/head";
import React from "react";
import "react-quill/dist/quill.bubble.css";
import '../public/css/globals.css'
import '../public/css/errors.min.css'
// import '../public/css/video.css'
// import "node_modules/video-react/dist/video-react.css";
import "react-quill/dist/quill.snow.css";
import { wrapper } from '../store/store'

function WrappedApp({ Component, pageProps }) {

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <React.Fragment>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        {/* PWA primary color */}
        <meta name="theme-color" content={theme.palette.primary.main} />

        <meta name="coverage" content="Worldwide" />
        <meta name="allow-search" content="yes" />
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="192x192" href="/hackerpost_180x180"></link>
        <link rel="apple-touch-icon" sizes="180x180" href="/hackerpost_192x192"></link>
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...pageProps} />
        {/* <style jsx global>
          {
            `
            body,html{
              margin:0;
              padding:0;
            }
            `
          }
        </style> */}
      </ThemeProvider>
    </React.Fragment>
  );
}

export default wrapper.withRedux(WrappedApp)