import * as actionTypes from '../../types';
import { updateObject } from '../../../helpers/reduxUtil'

const initState = {
    interval: null,
    takeBreakData: null,
    error: null,
    status: ''
}

function requestTimer(state, action) {
    return updateObject(state, {
        interval: action.interval
    })
}

function requestTakeBreak(state, action) {
    return updateObject(state, {
        status: action.status
    })
}
function takeBreakSuccess(state, action) {
    return updateObject(state, {
        takeBreakData: action.takeBreakData,
        status: action.status
    })
}
function takeBreakError(state, action) {
    return updateObject(state, {
        error: action.payloas
    })
}

//Anouncement Reducer
export default function getIntervalReducer(state = initState, action) {

    switch (action.type) {

        case actionTypes.REQUEST_TIMER_INTERVAL: return requestTimer(state, action);

        case actionTypes.REQUEST_TAKEBREAK_DATA: return requestTakeBreak(state, action);

        case actionTypes.TAKEBREAK_DATA_SUCCESS: return takeBreakSuccess(state, action);

        case actionTypes.TAKEBREAK_DATA_ERROR: return takeBreakError(state, action);


        default: return state;
    }

}