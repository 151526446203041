import * as actionTypes from '../../types'
import { updateObject } from '../../../helpers/reduxUtil';


const initState = {
    homepageArticles: [],
    status: "",
    error: null,
    filterBy: 'Newest',
    offset: 0,
    next: ''
}

function requestFilter(state, action) {
    return updateObject(state, {
        status: "loading"
    });
}

function choosedFilter(state, action){
    return updateObject(state, {
        filterBy: action.filterBy,
        next: action.next,
        homepageArticles: []
    })
}

function filterByNewest(state, action) {
    return updateObject(state, {
        homepageArticles: [...state.homepageArticles, ...action.newestArticles],
        next: action.next
    })
}

function filterByMinViews(state, action) {
    return updateObject(state, {
        next: action.next,
        homepageArticles: [...state.homepageArticles, ...action.minViewedArticles]
    })
}

function filterByMaxViews(state, action) {
    return updateObject(state, {
        homepageArticles: [...state.homepageArticles, ...action.maxViewedArticles],
        next: action.next
    })
}


function filterError(state, action){
    return updateObject(state, {
        error: action.error
    })
}


function firstTenArticlesError(state, action) {
    return updateObject(state, {
        status: 'error',
        error: action.error
    })
}

function firstTenArticlesSuccess(state, action) {
    return updateObject(state, {
        status: '',
        homepageArticles: action.articles,
    })
}


// Filter article Reducers
export default function filteredArticleReducers(state = initState, action) {

    switch (action.type) {

        case actionTypes.FILTER_TYPE:
            return choosedFilter(state, action);

        case actionTypes.FILTER_REQUEST:
            return requestFilter(state, action);

        case actionTypes.FILTER_BY_NEWEST:
            return filterByNewest(state, action);

        case actionTypes.FILTER_BY_MAXVIEWS:
            return filterByMaxViews(state, action);

        case actionTypes.FILTER_BY_MINVIEWS:
            return filterByMinViews(state, action);

        case actionTypes.FILTER_ERROR:
            return filterError(state, action);

        case actionTypes.REQUEST_STARTING_TEN_ARTICLES:
            return firstTenArticlesError(state, action);

        case actionTypes.SUCCESS_STARTING_TEN_ARTICLES:
            return firstTenArticlesSuccess(state, action);
        
        default: return state;
    }

}